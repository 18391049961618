import React, { useState, useEffect, useMemo, useContext } from "react";
import styles from "./MarketOverview.module.css";
import Tooltip from "../Tooltip/Tooltip";
import {
  BASE_URL,
  MARKET_OVERVIEW_HOME,
  MARKET_OVERVIEW_INIT,
} from "../../constants/constants";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { fixToTwoDecimals } from "../../utils/helpers";


const MarketOverview = ({ dashboardState }) => {
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const [sortConfig, setSortConfig] = useState({
    key: "rank",
    direction: "asc",
  });
  const [isLoading, setIsLoading] = useState(true); // New state variable
  const [tableClass, setTableClass] = useState("");
  const [collectionNames, setCollectionNames] = useState([]);
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      // try {

        const result = state.context.modelState;
        const oracleData = result.oracle || {}

        const formattedData = Object.keys(oracleData)?.map((key, index) => ({
          rank: index + 1,
          symbol: key,
          symbolImage: `/images/${key}icon.svg`,
          currentPrice: fixToTwoDecimals(oracleData[key].current_price, 3),
          predictedPrice:fixToTwoDecimals(parseFloat(oracleData[key].predicted_price), 3),
          residual: oracleData[key].residual,
        }));

        // Sort by currentPrice to determine rank
        formattedData.sort((a, b) => b.currentPrice - a.currentPrice);
        formattedData.forEach((item, index) => {
          item.rank = index + 1;
        });

        setData(formattedData);

        if (formattedData.length > 3) {
          setTableClass(styles.largeTable);
        } else {
          setTableClass("");
        }
        setIsLoading(false); // Set loading to false once data is loaded
      // } catch (error) {
      //    if (error.response && error.response.status === 401) {
      //     send({ type: "SIGN_OUT" });
      //     localStorage.removeItem("authState");
      //     disconnect();
      //   } else {
      //     console.error("Error fetching data:", error);
      //   }
      // }
    };
    if (dashboardState && state?.context?.modelState && Object.keys(state?.context?.modelState).length > 0) {
      fetchData();
    }
  }, [dashboardState,state.context]);

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key) {
      direction = sortConfig.direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort ascending"
          className={styles.sortIcon}
        />
      ) : (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort descending"
          className={`${styles.sortIcon} ${styles.inverted}`}
        />
      );
    }
    return (
      <img
        src="/images/tableSortArrow.svg"
        alt="Sort"
        className={`${styles.sortIcon} ${styles.inactive}`}
      />
    );
  };

  const columns = MARKET_OVERVIEW_HOME;

  const sortedData = useMemo(() => {
    let result = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

    // Ensure at least 3 rows
    while (result.length < 3) {
      result.push({
        rank: "--",
        symbol: "--",
        currentPrice: "--",
        predictedPrice: "--",
        residual: "--",
      });
    }

    return result;
  }, [data, sortConfig]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Market Overview</div>
          <Tooltip id="market-overview" page="home-page" />
        </div>
        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={sortable ? () => sortData(key) : undefined}
                    aria-sort={sortConfig.key === key ? sortConfig.direction : 'none'}>
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  <td>{isLoading ? '--' : item.rank}</td>
                  <td>
                    <div className={styles.symbolCell}>
                      <span>{item.symbol}</span>
                    </div>
                  </td>
                  <td>{item.currentPrice === '--' ? '--' : `$${fixToTwoDecimals(+item.currentPrice,2, false)}`}</td>
                  <td>{item.predictedPrice === '--' ? '--' : `$${fixToTwoDecimals(+item.predictedPrice, 2, false)}`}</td>
                  <td className={item.residual >= 0 ? styles.positive : styles.negative}>
                    {item.residual === '--' ? '--' : `${item.residual > 0 ? '+' : ''}${fixToTwoDecimals(item.residual,2, true)}%`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MarketOverview;
