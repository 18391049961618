import React from "react";
import Plot from "react-plotly.js";

function PerformanceChart({ data }) {
  //console.log(data);
  const timeSeries = data?.time_series?.timestamp || [];
  const close = data?.time_series?.close || [];
  const z_score = data?.time_series?.z_score_residual || [];
  const cumulativeReturns = data?.time_series?.cumulative_returns || [];
  const threshold = data?.config?.strategy_params?.threshold || 1.5;
  // Custom colorscale with transparency between -threshold and threshold
  const CustomLightRedYellowGreen = [
    [0, "rgba(255,99,71,0.8)"], // Lightened Red
    [0.1, "rgba(255,140,0,0.8)"], // Dark Orange
    [0.2, "rgba(255,165,0,0.8)"], // Orange
    [0.25, "rgba(255,215,0,0.8)"], // Gold
    // Transparent section for values between -threshold and threshold
    [0.3, "rgba(0, 0, 0, 0)"], // Fully transparent (black color, 0 opacity)
    [0.4, "rgba(0, 0, 0, 0)"], // Fully transparent (black color, 0 opacity)
    [0.6, "rgba(0, 0, 0, 0)"], // Fully transparent (black color, 0 opacity)
    [0.7, "rgba(0, 0, 0, 0)"], // Fully transparent (black color, 0 opacity)

    [0.75, "rgba(173,255,47,0.8)"], // Green-Yellow
    [0.8, "rgba(50,205,50,0.8)"], // Lime Green
    [0.9, "rgba(34,139,34,0.8)"], // Forest Green
    [1, "rgba(0,128,0,0.8)"], // Green
  ];
  const traces = [
    {
      x: timeSeries,
      y: close,
      type: "scatter",
      mode: "lines+markers",
      name: "Close Price",
      line: { color: "rgba(255, 255, 255, 1)" }, // White line
      marker: {
        size: 6,
        colorscale: CustomLightRedYellowGreen, // Red-Yellow-Green color scale
        cmin: -threshold * 2,
        cmax: threshold * 2,
        color: z_score, // Use z_score directly for color mapping
        colorbar: {
          title: 'Z-Score',
          titleside: 'right',
        },
      },
      hovertemplate:
        "Date: %{x}<br>Close: %{y}<br>Z-Score: %{marker.color:.2f}<extra></extra>",
        
    },

    {
      x: timeSeries,
      y: cumulativeReturns,
      type: "bar",
      name: "Cumulative Returns",
      marker: {
        color: cumulativeReturns.map((value) =>
          value > 0 ? "rgba(50,205,50,0.7)" : "rgba(255,99,71,0.7)"
        ), // Green for positive, Red for negative returns
      },
      hovertemplate: "Date: %{x}<br>Cumulative Returns: %{y}<extra></extra>", // Empty <extra></extra> removes trace name
      yaxis: "y2",
      showlegend:false,
    },
  ];

  const layout = {
    paper_bgcolor: "rgba(18, 18, 18, 0.1)", // Semi-transparent background
    plot_bgcolor: "rgba(18, 18, 18, 0.1)", // Semi-transparent background

    xaxis: {
      // title: 'Date',
      domain: [0, 1],
      type: "date",
      range:
        timeSeries?.length > 0
          ? [
              new Date(
                new Date(timeSeries[0]).getTime() - 24 * 60 * 60 * 1000
              ).toISOString(), // Subtract one day from the start
              new Date(
                new Date(timeSeries[timeSeries.length - 1]).getTime() +
                  24 * 60 * 60 * 1000
              ).toISOString(), // Add one day to the end
            ]
          : [
              new Date().toISOString().substring(0, 12),
              new Date().toISOString().substring(0, 13),
            ], // Fallback to the current date if timeSeries is empty
      anchor: "y2",
      color: "#FFFFFF",
      gridcolor: "transparent", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
      autorange: false, // Disable autoscale to manually control the range
    },
    yaxis: {
      title: "Price",
      domain: [0.3, 1],
      color: "#FFFFFF",
      gridcolor: "#444444", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
    },
    yaxis2: {
      title: "Return",
      tickformat: ".2%",
      domain: [0, 0.25],
      anchor: "x",
      color: "#FFFFFF",
      gridcolor: "transparent", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
    },
    legend: {
      orientation: "h",
      y: 1,
      x: 0.8,
      xanchor: "center",
      yanchor: "bottom",
      font: { color: "#FFFFFF" },
      bgcolor: "rgba(18, 18, 18, 0.5)", // Semi-transparent background (50% opacity)
      bordercolor: "rgba(51, 51, 51, 0.3)", // Lighter and more transparent border (30% opacity)
      borderwidth: 1, // Optional: You can reduce the border width to make it even more subtle
    },
    grid: { rows: 2, columns: 1, pattern: "independent" },
    height: 440,
    font: { color: "#FFFFFF" },
    showlegend: true, // Hide default legend
    margin: {
      l: 100, // left margin
      r: 100, // right margin
      t: 50, // top margin (reduced to remove extra space)
      b: 100, // bottom margin
    },
  };
  const config = {
    responsive: true, // Allow chart to be responsive
    displayModeBar: true, // Show the modebar
    modeBarButtonsToRemove: [
      "select2d", // Removes 2D selection
      "lasso2d", // Removes lasso tool
      "zoomIn2d", // Removes zoom in
      "zoomOut2d", // Removes zoom out
      "toImage",
      "resetScale2d", // Removes reset scale button


    ],
    displaylogo: false, // Remove the Plotly logo from the modebar
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Plot
        data={traces}
        layout={layout}
        style={{ width: "100%", height: "100%" }}
        config={config}
      />
    </div>
  );
}

export default React.memo(PerformanceChart);