import { useDisconnect } from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./StrategyOverview.module.css";
import { fixToTwoDecimals } from "../../utils/helpers";

const StrategyOverview = ({
  performanceData,
  selectedModel,
  selectedProjectId,
  dashboardState,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [modelState, setModelState] = useState(null);
  const [strategyState, setStrategyState] = useState(null); // New state for strategy state
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);

  
  useEffect(() => {
    const fetchModelState = async () => {
      try {
        const response = state.context.modelState[selectedModel][selectedProjectId]
        setModelState(response);
      } catch (error) {
        console.error("Error fetching model state:", error);
      }
    };
    if (selectedModel && selectedProjectId && dashboardState && state.context?.modelState) {
      fetchModelState();
    }
  }, [selectedModel, selectedProjectId, dashboardState, state.context?.modelState]);

  useEffect(() => {
    const fetchStrategyState = async () => {
      try {
        const response = state.context.strategyState[selectedModel][selectedProjectId]
        setStrategyState(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          send({ type: "SIGN_OUT" });
          localStorage.removeItem("authState");
          disconnect();
        }
        console.error("Error fetching strategy state:", error);
      }
    };

    if(dashboardState && selectedModel && selectedProjectId && state.context.strategyState) fetchStrategyState();
    }, [selectedModel, selectedProjectId, dashboardState, state.context.strategyState]);

  const configData = modelState?.config;
  //console.log(modelState);
  //console.log(strategyState);
  //console.log(configData);

  const getColor = (value, threshold) => {
    if (value > threshold) return "#00ff00";
    if (value < -threshold) return "#ff000";
    return "yellow";
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.headerLayout}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Strategy Overview</div>
            <Tooltip id="strategy-overview" page="strategy-page" />
          </div>
        </div>
        <div className={styles.dashboard}>
          <div className={styles.panel}>
            <div className={styles.panelTitle}>Strategy Performance</div>
            <div className={styles.panelContent}>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Sharpe</span>
                <span
                  className={styles.dataValue}
                  style={{ color: strategyState?.sharpe > 0 ? "#00ff00" : "#ff000" }}
                >
                  {fixToTwoDecimals(strategyState?.sharpe, 2) || "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Sortino</span>
                <span
                  className={styles.dataValue}
                  style={{ color: strategyState?.sortino > 0 ? "#00ff00" : "#ff000" }}
                >
                  {fixToTwoDecimals(strategyState?.sortino,2) || "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Winrate</span>
                <span
                  className={styles.dataValue}
                  style={{
                    color: strategyState?.winrate > 50 ? "#00ff00" : "#ff000",
                  }}
                >
                  {strategyState?.winrate
                    ? `${fixToTwoDecimals(strategyState.winrate, 2, true)}%`
                    : "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>1Y Return</span>
                <span
                  className={styles.dataValue}
                  style={{
                    color: strategyState?.["1y_return"] > 0 ? "#00ff00" : "#ff000",
                  }}
                >
                  {strategyState?.["1y_return"]
                    ? `${fixToTwoDecimals((strategyState["1y_return"] * 100), 2, true)}%`
                    : "--"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panelTitle}>Strategy State</div>
            <div className={styles.panelContent}>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Actual Price</span>
                <span className={styles.dataValue}>
                  {fixToTwoDecimals(modelState?.current_price, 2) || "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Price</span>
                <span className={styles.dataValue}>
                  {fixToTwoDecimals(modelState?.predicted_price ,2) || "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Residual</span>
                <span
                  className={styles.dataValue}
                  style={{
                    color: getColor(
                      modelState?.residual,
                      configData?.strategy_params.threshold
                    ),
                  }}
                >
                  {modelState?.residual
                    ? `${fixToTwoDecimals(modelState.residual, 2, true)}%`
                    : "--"}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span className={styles.dataLabel}>Z-Score</span>
                <span
                  className={styles.dataValue}
                  style={{
                    color: getColor(
                      modelState?.z_score,
                      configData?.strategy_params.threshold
                    ),
                  }}
                >
                  {fixToTwoDecimals(modelState?.z_score) || "--"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panelTitle}>Strategy Explained</div>
            <div className={styles.explanationDiv}>
              <p className={styles.explanation}>
                {configData ? (
                  <>
                    This strategy uses a{" "}
                    {configData.model_params.type.replace(/_/g, " ")} model with
                    a {configData.xy_params.xy_window} lookback window. It
                    triggers trades when the {configData.strategy_params.metric}{" "}
                    exceeds a threshold of{" "}
                    {configData.strategy_params.threshold}, using a{" "}
                    {configData.strategy_params.window}-day window. Each trade
                    size is {configData.strategy_params.size * 100}% of the
                    portfolio.
                  </>
                ) : (
                  "Loading configuration data..."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyOverview;