import { useEffect, useState } from 'react';
import axios from 'axios';

const useIpLookup = () => {
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    const fetchIPData = async () => {
      try {
        // const ipResponse = await axios.get(
        //   "https://api.whatismyip.com/ip.php?key=b08d396917b15fe4d666712bfe8a1b56	"
        // );
        // const ipAddress = ipResponse.data;

        const lookupResponse = await axios.get(
          // `https://ipinfo.io?token=9af27d71ba0f6d`
          // "https://api.ip2location.io/?key=6166A646A7EE1D8B7D9FDBB4EDE98748"
          'https://apiip.net/api/check?accessKey=f61831bf-1b98-4123-9c36-b0714540c3b3'
        );
        const geolocation = lookupResponse.data;

        // const parsedGeolocation = JSON.parse(geolocation);
        // console.log(geolocation.countryCode);

        const country = geolocation.ip;

        // console.log("Country:", country);

        setIpAddress(country);
      } catch (error) {
        console.error('Error fetching IP data:', error);
      }
    };

    fetchIPData();
  }, []);

  return ipAddress;
};

export default useIpLookup;
