import React, { useEffect, useState, useContext } from "react";
import styles from "./Ads.module.css";
import { useErrorContext } from "../../context/ErrorContext";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";

const Ads = () => {
  const [adData, setAdData] = useState(null);
  const [error, setError] = useState(null);
  const { axiosInstance } = useErrorContext();
  const { state } = useContext(AuthContext);
  // useEffect(() => {
  //   const fetchAdData = async () => {
  //     try {
  //       const response = await axiosInstance.get(`ad?option=dashboard`);
  //       const data =response.data

  //       if (data.img) {
  //         data.img = convertBase64ToImageUrl(data.img);
  //       }

  //       setAdData(data);
  //     } catch (err) {
  //       setError(err.message);
  //     }
  //   };
  //   fetchAdData();
  // }, []);

  const convertBase64ToImageUrl = (base64String) => {
    const imageData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(imageData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < imageData.length; i++) {
      uint8Array[i] = imageData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  };

  if (error) {
    return <div className={`${styles.adsWrapper} ${styles.error}`}></div>;
  }

  if (state.context?.Ads) {
    return (
      <div className={styles.gradient} onClick={() => window.open(state.context?.Ads.url, '_blank')}>
        {state.context?.Ads && (
          <div className={styles.adsWrapper}>
            {state.context?.Ads.img && (
              <img src={convertBase64ToImageUrl(state.context?.Ads.img)} alt={state.context?.Ads.text} className={styles.adImage} />
            )}
            <div className={styles.textDiv}>{state.context?.Ads.text}</div>
          </div>
        )}
      </div>
    );
  }
};

export default Ads;
