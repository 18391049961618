import React from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import styles from './expiryProgressBar.module.css';

const ExpiryProgressBar = ({ expiryDate, totalDays = 30 }) => {
  // Calculate days remaining
  const today = new Date();
  const expiry = parseISO(expiryDate);
  const daysDifference = differenceInDays(expiry, today);
  let daysRemaining = daysDifference < 0 ? 0 : daysDifference; // Set to 0 if expired

  // Ensure daysRemaining does not exceed totalDays
  if (daysRemaining > totalDays) {
    daysRemaining = totalDays; // Cap daysRemaining to totalDays
  }
  // Calculate progress percentage (100% to 0%)
  const progress = Math.min(Math.max((daysRemaining  / totalDays) * 100, 0), 100); // Invert the progress calculation

  return (
    <div>
      {/* Header with days count */}
      <div className={styles.expiryProgressHeader}>
        <span className={styles.daysText}>Days</span>
        <span className={styles.daysCount}>{daysRemaining} of {totalDays} days left</span>
      </div>
      
      {/* Progress bar */}
      <div className={styles.progressBarContainer}>
        <div 
          className={styles.progressBar}
          style={{ width: `${progress}%` }} // Reflects the progress based on days remaining
        />
      </div>
      
      {/* Days remaining message */}
      <div className={styles.remainingMessage}>
        {daysRemaining} days remaining until your plan requires an update
      </div>
    </div>
  );
};

export default ExpiryProgressBar;