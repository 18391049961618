import React, { createContext, useContext, useState } from "react";
import { createAxiosInstance } from "../utils/axiosInstance";

const ErrorContext = createContext();

export const useErrorContext = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const axiosInstance = createAxiosInstance(setError);
  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider
      value={{ error, setError, clearError, axiosInstance }}
    >
      {children}
    </ErrorContext.Provider>
  );
};