import React, { useContext, useEffect } from 'react';
import withLayout from '../components/withLayout/withLayout';
import MdDocs from '../components/MdDocs/MdDocs';
import { AuthContext } from '../AuthWrapper/AuthWrapper';

const Docs = () => {
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (state.matches('model') || state.matches('home') || state.matches('strategy') || state.matches('apiKey')) send({ type: 'CHECK_ROUTE' });
  }, [send]);

  return (
    <div style={{ color: 'white', width: '100%' }}>
      <MdDocs />
    </div>
  );
};

export default Docs;
