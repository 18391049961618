import React, { useContext } from 'react';
import styles from './ConnectModal.module.css';
import { useWeb3Modal } from '@web3modal/ethers/react';
import { PERMANENT_AMOUNT, EXTERNAL_LINKS as SOCIAL_MEDIA_LINKS } from '../../constants/constants.js'; // Import the constant
import { useWalletContext } from '../../context/WalletContext.js'; // Import the context
import { useErrorContext } from '../../context/ErrorContext.js';
import Loader from '../Loader/Loader';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';

const ConnectModal = () => {
  const { error } = useErrorContext();
  const { open } = useWeb3Modal();
  const { tokenSymbol } = useWalletContext(); // Use the context to get the token symbol
  const { state, send } = useContext(AuthContext);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.title}>{state.matches('signingIn') ? 'Connecting the wallet' : 'Please connect your wallet'}</div>
        <div className={styles.subtitle}>You must hold {PERMANENT_AMOUNT} $YAI or have an active subscription to access the dashboard</div>
        {error && (
          <div className={styles.buttonWallet} style={{ color: 'red', marginBottom: '16px', fontSize: '14px' }}>
            {error}
          </div>
        )}
        {state.matches('signingIn') ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.buttonWallet} onClick={() => open()}>
              <img src='/images/walletIcon.svg' alt='Wallet' />
              Wallet
            </div>
            <a
              href='https://www.coingecko.com/en/coins/y'
              className={`${styles.buttonWallet} ${styles.tooltipContainer}`}
              // onClick={() => open()}
            >
              {/* <img src="/images/walletIcon.svg" alt="Wallet" /> */}
              Buy YAI
              <span className={styles.tooltip}>
                Third party platform link. YAI can not be held responsible for token availability, liquidity or stability of third party platforms
              </span>
            </a>
            <div className={styles.socialMedia}>
              <a href={SOCIAL_MEDIA_LINKS.X} target='_blank' rel='noopener noreferrer'>
                <img
                  style={{ height: '30px', width: '30px', background: '#151814', padding: '10px', borderRadius: '50%' }}
                  src='/images/twitter.svg'
                  alt='X'
                />
              </a>
              <a href={SOCIAL_MEDIA_LINKS.Telegram} target='_blank' rel='noopener noreferrer'>
                <img
                  style={{ height: '30px', width: '30px', background: '#151814', padding: '10px', borderRadius: '50%' }}
                  src='/images/telegram.svg'
                  alt='Telegram'
                />
              </a>
              <a href={SOCIAL_MEDIA_LINKS.Docs} target='_blank' rel='noopener noreferrer'>
                <img style={{ height: '50px', width: '50px' }} src='/images/docsIcon.svg' alt='Docs' />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectModal;
