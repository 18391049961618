import React from "react";

const HamburgerIcon = ({ width = 24, height = 24, color = "#ffff" }) => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="41"
        height="39"
        rx="14.5"
        fill="#121410"
        stroke="url(#paint0_linear_2_57357)"
      />
      <g opacity="0.5">
        <path
          d="M27 26V28H15V26H27ZM30 19V21H12V19H30ZM27 12V14H15V12H27Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_57357"
          x1="42"
          y1="20"
          x2="0"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#353A33" stopOpacity="0" />
          <stop offset="1" stopColor="#353A33" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HamburgerIcon;
