import React from 'react';
import styles from './Header.module.css'; 
import {
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

function Header() {
  const { address, isConnected,status } = useWeb3ModalAccount();
  //console.log('address from header', address, status);
  //console.log('isConnected from header', isConnected);

 
  const shortenAddress = (address) => {
    return `${address.slice(0, 4)}....${address.slice(-4)}`;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.welcomeMessage}>
            {isConnected ? (
              <span>
                Hey <span className={styles.highlight}>{shortenAddress(address)}</span>, welcome to the future.
              </span>
            ) : (
              <span>
                Hey! Please connect your wallet.
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          {/* <div className={styles.profile}>
            <img src='/images/photoIcon.svg' alt='profile' className={styles.profilePic} />
            <div className={styles.profileInfo}>
              <span className={styles.profileName}>Hazel Francis</span>
              <span className={styles.profileEmail}>hazel@contact.com</span>
            </div>
          </div>
          <div className={styles.settingButtonContainer}>
            <button className={styles.settingsButton}>
              <img src='images/settingsIcon.svg' alt="settings" />
              Settings
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
