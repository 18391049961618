import React, { createContext, useContext, useState, useEffect } from 'react';
import { useYContract } from '../constants/useContracts.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { ethers } from 'ethers';

const WalletContext = createContext();

export const useWalletContext = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [walletBalance, setWalletBalance] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const tokenContract = useYContract();
  const { address, isConnected, chainId } = useWeb3ModalAccount();

  const handleWalletBalance = (balance) => {
    setWalletBalance(balance);
  };

  useEffect(() => {
    const getYaiTokenBalance = async () => {
      if (isConnected && address) {
        try {
          const [rawBalance, symbol] = await Promise.all([tokenContract.balanceOf(address), tokenContract.symbol()]);
          const balanceFormatted = ethers.formatEther(rawBalance);

          setTokenBalance(balanceFormatted);
          setTokenSymbol(symbol);
          //console.log('this is the balance>>>', balanceFormatted);

          return balanceFormatted;
        } catch (error) {
          console.error('Error fetching token balance:', error);
          if (error.reason) {
            console.error('Error reason:', error.reason);
          }
          setTokenBalance(null);
          setTokenSymbol('');
          return null;
        }
      } 
      // No action needed if not connected or address is missing
    };

    getYaiTokenBalance();
  }, [isConnected, address, chainId]);

  return (
    <WalletContext.Provider value={{ tokenBalance, tokenSymbol, walletAddress, walletBalance, handleWalletBalance, tokenBalance, tokenSymbol }}>
      {children}
    </WalletContext.Provider>
  );
};
