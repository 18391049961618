// import { Fixed_Amount_Upto_Below_Threshold,Fixed_Amount_Upto_Above_Threshold,Fixed_Amount_threshold } from "../constants/constants";
import { AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION } from "../constants/constants";

export const extractProjectIds = (dashboardState) => {
    const projectIdsSet = new Set();
    Object.values(dashboardState).forEach((projectList) => {
      projectList.forEach((projectId) => projectIdsSet.add(projectId));
    });
    return Array.from(projectIdsSet);
  }


  export const fixToTwoDecimals = (value, digits, isPercentage = false) => {
    if (isNaN(value)) value = 0;

    let decimalPlaces;
    if (isPercentage) {
      // If it's a percentage and below 10, use 2 decimal places; otherwise, use digits if provided
      decimalPlaces = (value < 10) ? 2 : (digits !== undefined ? digits : 2);
    } else {
      // If it's a regular value and below 10, use 3 decimal places; otherwise, use digits if provided
      decimalPlaces = (value < 10) ? 3 : (digits !== undefined ? digits : 3);
    }

    return parseFloat(value).toFixed(decimalPlaces);
  };


  export const getNextHourTimestamp = () => {
    const now = new Date();
    now.setHours(now.getHours() + 1, 0, 0, 0);
    return now.toISOString().slice(0, 19);
  };


  // Function to calculate total number of subscription days
  export const calculateSubscriptionDays = (value) => {
    const BASE_AMOUNT = parseFloat(AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION || "0.0");

    const dailyRate = BASE_AMOUNT / 30; // Assuming 30 days in a month
    const subscriptionDays = Math.floor(value / dailyRate);
    return subscriptionDays;
  };


  // Example usage



  