import { useDisconnect } from "@web3modal/ethers/react";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { getNextHourTimestamp } from "../../utils/helpers.js";
import Loader from "../Loader/Loader.js";
import Tooltip from "../Tooltip/Tooltip.js";
import styles from "./HistoricalOverview.module.css";
import PerformanceChart from "./PerformanceChart";

function HistoricalOverviewModel({
  dashboardState,
  initialModel,
  initialProjectId,
}) {
  const oldinitialModel = useMemo(() => initialModel, []);
  const [uiState, setUiState] = useState({
    isLoading: false,
    isProjectDropdownOpen: false,
    isModelDropdownOpen: false,
  });
  const currentTimestamp = getNextHourTimestamp();

  const { state, send } = useContext(AuthContext);

  const [chartData, setChartData] = useState(
    state?.context?.modelHistory?.close?.length
      ? {
          ...state.context.modelHistory,
        }
      : {
          close: [],
          model: [],
          model_timestamps: [],
          close_timestamps: [],
          predicted_price: [],
        }
  );

  const [selections, setSelections] = useState({
    models: [],
    projectIds: [],
  });

  // Helper functions
  const selectInitialModel = useCallback((availableModels, initial) => {
    return initial && availableModels.includes(initial)
      ? initial
      : state.context?.selectedHistoricalModel
      ? state.context?.selectedHistoricalModel
      : availableModels[0];
  }, []);

  const selectInitialProjectId = useCallback(
    (availableProjectIds, initial) => {
      if (initial && availableProjectIds.includes(initial)) {
        return initial;
      }

      if (
        state.context?.selectedIds?.historical &&
        availableProjectIds.includes(state.context.selectedIds.historical)
      ) {
        return state.context.selectedIds.historical;
      }

      return availableProjectIds[0];
    },
    [state.context?.selectedIds?.historical]
  );

  // // Memoized chart data
  // useEffect(() => {
  //   setChartData((prevChartData) => {
  //     const newChartData = state?.context?.modelHistory?.close?.length
  //       ? { ...state.context.modelHistory }
  //       : {
  //           close: [],
  //           model: [],
  //           model_timestamps: [],
  //           close_timestamps: [],
  //           predicted_price: [],
  //         };

  //     // console.table("Previous chartData:", prevChartData);
  //     // console.table("New chartData:", newChartData);

  //     return newChartData;
  //   });
  // }, [state.context.modelHistory]);

  useEffect(() => {
    if (!dashboardState) {
      setSelections({ models: [], projectIds: [] });
      // updateModelAndProject(null, null);
      return;
    }

    const availableModels = Object.keys(dashboardState);
    const currentModel = selectInitialModel(availableModels, initialModel);
    const availableProjectIds = dashboardState[currentModel] || [];
    const currentProjectId = selectInitialProjectId(
      availableProjectIds,
      initialProjectId
    );

    setSelections({
      models: availableModels,
      projectIds: availableProjectIds,
    });

    updateModelAndProject(currentModel, currentProjectId);
  }, [dashboardState, initialModel, initialProjectId]);

  useEffect(() => {
    const shouldFetchData =
      state.context?.selectedHistoricalModel &&
      state.context?.selectedIds?.historical &&
      state.context?.accessToken;

    if (shouldFetchData && !state.matches("idle")) {
      send({ type: "REFRESH_MODEL_HISTORY" });
    }

    // Update the previous model reference
  }, [
    state.context?.selectedHistoricalModel,
    state.context?.selectedIds?.historical,
    state.context?.accessToken,
  ]);

  // useEffect(() => {
  //   if (state.context?.modelState && state.context?.realTime && !!chartData.close.length) {
  //     const modelData = state.context.modelState?.[state.context?.selectedHistoricalModel]?.[state.context?.selectedIds?.historical];
  //     if (!modelData?.current_price) return;

  //     const currentTimestamp = getNextHourTimestamp();

  //     send({
  //       type: "UPDATE_MODEL_HISTORY_DATA",
  //       payload: {
  //         data: {
  //           ...chartData,
  //           close: [...chartData.close, modelData.current_price],
  //           predicted_price: [...(chartData.predicted_price || []), modelData.predicted_price],
  //           close_timestamps: [...chartData.close_timestamps, currentTimestamp],
  //           model_timestamps: [...chartData.model_timestamps, currentTimestamp],
  //         }
  //       }
  //     });

  //     setChartData({
  //       ...chartData,
  //       close: [...chartData.close, modelData.current_price],
  //       predicted_price: [...(chartData.predicted_price || []), modelData.predicted_price],
  //       close_timestamps: [...chartData.close_timestamps, currentTimestamp],
  //       model_timestamps: [...chartData.model_timestamps, currentTimestamp],
  //     })
  //   }
  // }, [state.context?.modelState, state.context?.selectedHistoricalModel, state.context?.selectedIds?.historical, chartData]);

  const updateModelAndProject = useCallback(
    (model, projectId) => {
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          model,
          type: "historical",
          projectId,
        },
      });
    },
    [send]
  );

  const handleModelSelect = useCallback(
    (model) => {
      const newProjectIds = dashboardState[model] || [];
      const preferredProjectId = selectInitialProjectId(newProjectIds);

      setSelections((prev) => ({ ...prev, projectIds: newProjectIds }));
      updateModelAndProject(model, preferredProjectId);
      setUiState((prev) => ({ ...prev, isModelDropdownOpen: false }));
    },
    [
      dashboardState,
      state.context?.selectedHistoricalModel,
      state.context?.selectedIds?.historical,
    ]
  );

  const handleProjectSelect = useCallback(
    (projectId) => {
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          model: state.context?.selectedHistoricalModel,
          type: "historical",
          projectId,
        },
      });
      setUiState((prev) => ({ ...prev, isProjectDropdownOpen: false }));
    },
    [state.context?.selectedHistoricalModel]
  );

  const toggleDropdown = useCallback((dropdownType) => {
    setUiState((prev) => ({
      ...prev,
      [dropdownType]: !prev[dropdownType],
    }));
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="model-page" />
        </div>
        <div className={styles.mainDropdownContainer}>
          <div className={styles.dropdownContainer}>
            <div
              className={styles.dropdown}
              onClick={toggleDropdown.bind(null, "isModelDropdownOpen")}
            >
              <span className={styles.selectedOption}>
                {state.context?.selectedHistoricalModel}
              </span>
              <span className={styles.arrow}>
                {uiState.isModelDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {uiState.isModelDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {selections.models.map((model) => (
                  <div
                    key={model}
                    className={styles.dropdownItem}
                    onClick={() => handleModelSelect(model)}
                  >
                    {model}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <div
              className={styles.dropdown}
              onClick={toggleDropdown.bind(null, "isProjectDropdownOpen")}
            >
              <span className={styles.selectedOption}>
                {state.context?.selectedIds?.historical}
              </span>
              <span className={styles.arrow}>
                {uiState.isProjectDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {uiState.isProjectDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {selections.projectIds.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => handleProjectSelect(projectId)}
                  >
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src="images/chartBG.png" />
        </div>
        {state.matches("model.loading") && (
          <div>
            <Loader />
          </div>
        )}
        <PerformanceChart
          data={
            state?.context?.modelHistory?.close?.length
              ? {
                  ...state.context.modelHistory,
                }
              : {
                  close: [],
                  model: [],
                  model_timestamps: [],
                  close_timestamps: [],
                  predicted_price: [],
                }
          }
        />
      </div>
    </div>
  );
}

export default React.memo(HistoricalOverviewModel);
