import React, { useState, useEffect, useCallback, useContext } from "react";
import PerformanceChart from "./PerformanceChart";
import styles from "./HistoricalOverview.module.css";
import Loader from "../Loader/Loader.js";
import Tooltip from "../Tooltip/Tooltip.js";
// import { BASE_URL } from "../../constants/constants";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { getNextHourTimestamp } from "../../utils/helpers.js";

function HistoricalOverview({
  onDataFetched,
  selectedModel,
  selectedProjectId,
  onModelProjectChange,
  dashboardState,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [data, setData] = useState();
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (dashboardState) {
      setModels(Object.keys(dashboardState));
      setProjectIds(dashboardState[selectedModel] || []);
    }
  }, [dashboardState, selectedModel]);

  const fetchData = async () => {
    try {
      const result = state.context.strategyHistory;
      setData(result);
      onDataFetched(result.performance);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedModel &&
      selectedProjectId &&
      dashboardState &&
      state.context?.strategyHistory
    ) {
      setIsLoading(true);
      fetchData();
    }
  }, [
    dashboardState,
    selectedModel,
    selectedProjectId,
    state.context?.strategyHistory,
  ]);

  useEffect(() => {
    if (selectedModel && selectedProjectId && state.matches("strategy.idle")) {
      send({ type: "REFRESH_STRATEGY_HISTORY" });
    }
  }, [selectedModel, selectedProjectId]);

  // useEffect(() => {
  //   if (data && state.context.realTime) {
  //     send({
  //       type: "UPDATE_STRATEGY_DATA",
  //       payload: {
  //         data: data,
  //       },
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   const projectId =
  //     state.context?.selectedIds?.historical ||
  //     state.context?.dashboardState?.[
  //       state.context?.selectedHistoricalModel
  //     ]?.[0];
  //   const modelData =
  //     state.context.modelState?.[state.context?.selectedHistoricalModel]?.[
  //       projectId
  //     ];
  //   if (modelData && modelData.current_price !== undefined) {
  //     const currentTimestamp = getNextHourTimestamp();

  //     setData((prevData) => {
  //       if (prevData) {
  //         const time_series = prevData.time_series || {};

  //         const updatedThreshold =
  //           modelData?.config?.strategy_params?.threshold;
  //         const updatedConfig = prevData.config
  //           ? {
  //               ...prevData.config,
  //               strategy_params: {
  //                 ...prevData.config.strategy_params,
  //                 threshold: updatedThreshold,
  //               },
  //             }
  //           : prevData.config;

  //         if (
  //           time_series.close?.length > 0 ||
  //           time_series.predicted_price?.length > 0
  //         ) {
  //           return {
  //             ...prevData,
  //             config: updatedConfig,
  //             time_series: {
  //               ...time_series,
  //               close: [...(time_series.close || []), modelData.current_price],
  //               z_score_residual: [
  //                 ...(time_series.z_score_residual || []),
  //                 modelData.z_score,
  //               ],
  //               cumulative_returns: [
  //                 ...(time_series.cumulative_returns || []),
  //                 modelData.current_price,
  //               ],
  //               timestamp: [...(time_series.timestamp || []), currentTimestamp],
  //             },
  //           };
  //         } else {
  //           return prevData;
  //         }
  //       }
  //     });
  //   }
  // }, [state.context?.modelState]);

  useEffect(() => {
    if (typeof onModelProjectChange === "function") {
      const currentProjectId = projectIds.includes(selectedProjectId)
        ? selectedProjectId
        : projectIds[0] || selectedProjectId;
      if (models.length > 0 && !models.includes(selectedModel)) {
        // If the selected model is not in the list, select the first model and project
        onModelProjectChange(models[0], currentProjectId);
      } else if (
        selectedModel &&
        (!selectedProjectId || !projectIds.includes(selectedProjectId))
      ) {
        // If a model is selected but no project ID is selected (or the selected project ID is invalid),
        // keep the current model and select the first project for that model
        onModelProjectChange(selectedModel, currentProjectId);
      }
    }
  }, [
    models,
    selectedModel,
    projectIds,
    selectedProjectId,
    onModelProjectChange,
  ]);

  const toggleProjectDropdown = () =>
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  const toggleModelDropdown = () =>
    setIsModelDropdownOpen(!isModelDropdownOpen);

  const selectProjectId = (projectId) => {
    onModelProjectChange(selectedModel, projectId, true);
    setIsProjectDropdownOpen(false);
  };

  const selectModel = (model) => {
    const currentProjectId = dashboardState[model].includes(selectedProjectId)
      ? selectedProjectId
      : dashboardState[model][0];
    onModelProjectChange(model, currentProjectId, true);
    setIsModelDropdownOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="strategy-page" />
        </div>
        <div className={styles.mainDropdownContainer}>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleModelDropdown}>
              <span className={styles.selectedOption}>{selectedModel}</span>
              <span className={styles.arrow}>
                {isModelDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isModelDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {models?.map((model) => (
                  <div
                    key={model}
                    className={styles.dropdownItem}
                    onClick={() => selectModel(model)}
                  >
                    {model}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleProjectDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedProjectId?.toLowerCase()}.svg`} alt={selectedProjectId} /> */}
                {selectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isProjectDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isProjectDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds?.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {/* <img src={`/images/${projectId?.toLowerCase()}.svg`} alt={projectId} /> */}
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src="images/chartBG.png" />
        </div>
        {state.matches("strategy.loading") && <Loader />}

        <PerformanceChart data={data} />
      </div>
    </div>
  );
}

export default HistoricalOverview;
