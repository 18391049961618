import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useMachine } from "@xstate/react";
import React, { createContext, useEffect, useRef } from "react";
import { useSignMessage } from "../constants/getContracts";
import { authMachine } from "../xstate/authXstate";
import useIpLookup from "./useIpLookup";
import { useErrorContext } from "../context/ErrorContext";
import { fromPromise, assign } from "xstate";
import { catchError, EMPTY, map } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { BASE_URL, BASE_URL_WEBSOCKET } from "../constants/constants";
import axios from "axios";

export const AuthContext = createContext(null);

const AuthWrapper = ({ children }) => {
  const socketRef = useRef(null);
  const { isConnected, address } = useWeb3ModalAccount();
  const ipAddress = useIpLookup();
  const signMessage = useSignMessage();
  const { disconnect } = useDisconnect();
  const { axiosInstance, clearError, setError } = useErrorContext();
  const [state, send] = useMachine(
    authMachine.provide({
      actors: {
        fetchDashboardState: fromPromise(async ({ input }) => {
          const response = await axiosInstance.get("/dashboard_state");
          const result = response.data;
          return {
            result,
          };
        }),

        fetchModelsData: fromPromise(async () => {
          const response = await axiosInstance.get("/model_state");
          const result = response.data;

          return { result };
        }),
        
        fetchStrategyData: fromPromise(async () => {
          const response = await axiosInstance.get("/strategy_state");
          const result = response.data;

          return { result };
        }),
        
        fetchStrategyHistory: fromPromise(async ({input}) => {
          const response = await axiosInstance.get("/strategy_history", {
            params: {
              model: input.context?.selectedHistoricalModel,
              project_id: input.context?.selectedIds?.strategy,
              // start_date: '2023-09-07',
              // threshold: 1.5,
              // size: 0.05,
            },
          });
          const result = response.data;
          return {result}
        }),

        signInService: fromPromise(async ({ input }) => {
          try {
            const response = await axiosInstance.post("/login");
            const { access_token, master_key } = response?.data;
            return {
              accessToken: access_token,
              masterKey: master_key,
              ip_address: input.ip_address,
              wallet_address: input.wallet_address,
              signature: input.signature,
            };
          } catch (error) {
            throw new Error(
              JSON.stringify({
                ip_address: input.ip_address,
                wallet_address: input.wallet_address,
                signature: input.signature,
                error: error.response.data.detail,
              })
            );
          }
        }),

        verifyingSubscriptionInterval: fromPromise(async ({ input }) => {
          return new Promise((resolve, reject) => {
            const intervalId = setInterval(async () => {
              try {
                const response = await axiosInstance.get('/check-status');
                const { status, access_token } = response.data;

                if (status === "verified") {
                  clearInterval(intervalId);
                  resolve({ accessToken: access_token, status }); // Resolve the promise
                } else if (status !== "checking") {
                  clearInterval(intervalId);
                  reject(new Error("Unexpected status")); // Handle unexpected statuses
                }
              } catch (error) {
                setError(error.response?.data?.detail)
                
                clearInterval(intervalId);
                reject( new Error(error?.response?.data?.detail)); // Reject on error
              }
            }, 10000);
          });
        }),
        logoutService: fromPromise(async () => {
          try {
            const response = await axiosInstance.post("/logout");
          } catch (error) {
            setError(error.response?.data?.detail)
            throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchModelHistory: fromPromise(async ({input}) => {
          try {
            const response = await axiosInstance.get("/model_history", {
              params: {
                model: input.context?.selectedHistoricalModel,
                project_id: input.context?.selectedIds?.historical,
              },
            });
            const result = response.data?.time_series;
            return {result}
          } catch (error) {
            setError(error.response?.data?.detail)
            throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchDocs: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/dashboard_docs");
            const result = response?.data;

            return { result };
          } catch (error) {
            setError(error?.response?.data?.detail)
            // throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchAds: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/ad?option=dashboard");
            const result = response?.data;

            return { result };
          } catch (error) {
            setError(error?.response?.data?.detail)
            // throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchSubscriptions: fromPromise(async () => {
          try {
            const response = await axiosInstance.get(`/get-subscriptions`);
            const result = response.data;

            return { result };
          } catch (error) {
            setError(error.response?.data?.detail)
            throw new Error(error?.response?.data?.detail);
          }
        }),
      },
      actions: {
        disconnectFromMetamask: async () => {
          disconnect();
          localStorage.removeItem("authState");
          console.log("disconnectFromMetamask");
        },
        clearError: () => {
          clearError();
        },
      },
    })
  );

  console.log("this is state >>>>>>>", state);

  useEffect(() => {
    const signInUser = async () => {
      const authState = JSON.parse(localStorage.getItem("authState"));

      if (
        !authState?.accessToken &&
        ipAddress &&
        isConnected &&
        state.matches("idle")
      ) {
        try {
          const { signature } = await signMessage();
          send({
            type: "SIGN_IN",
            payload: {
              ip_address: ipAddress,
              wallet_address: address,
              signature,
            },
          });
        } catch (error) {
          console.error("Error signing message:", error);
        }
        return;
      }
    };

    signInUser();
  }, [isConnected]);

  useEffect(() => {
    const authState = JSON.parse(localStorage.getItem("authState"));
    const initializeWebSocket = () => {
      const accessToken = authState?.accessToken;
      const walletAddress = authState?.wallet_address;

      const socket$ = webSocket({
        url: `${BASE_URL_WEBSOCKET}?token=${encodeURIComponent(
          accessToken
        )}&walletAddress=${encodeURIComponent(walletAddress)}`,
        openObserver: {
          next: () => {
            console.log("WebSocket connected");
          },
        },
        errorObserver: {
          next: (error) => console.error("WebSocket error:", error),
        },
      });

      socketRef.current = socket$;

      const subscription = socket$
        .pipe(
          map((data) => {
            send({
              type: "UPDATE_MODEL_STATE_FROM_SOCKET",
              payload: JSON.parse(data),
            });
            return data;
          }),
          catchError((err) => {
            console.error("Error processing WebSocket data:", err);
            return EMPTY;
          })
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    };
    if (state.context?.accessToken) {
      initializeWebSocket();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.complete();
      }
    };
  }, [state.context?.accessToken]);

  return (
    <AuthContext.Provider value={{ state, send }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;