import React, { useState, useEffect, useContext, useMemo } from 'react';
import CustomGauge from './Gauge.js';
import styles from './Technicals.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import { extractProjectIds } from '../../utils/helpers.js';

function Technicals({ dashboardState }) {
  const initialSelectedProjectId = useMemo(() => {
    if (!dashboardState) return null;
    const projectIds = extractProjectIds(dashboardState);
    return projectIds.length > 0 ? projectIds[0] : null;
  }, [dashboardState]);
  
  const [isOpen, setIsOpen] = useState(false);
  const { state, send } = useContext(AuthContext);
  const residuals = useMemo(() => {
    if (!dashboardState || !state.context?.modelState?.oracle) return {};
    
    const projectId = extractProjectIds(dashboardState)[0];
    return state.context.modelState.oracle[state?.context?.selectedIds?.technical ?? projectId] || {};
  }, [dashboardState, state.context?.modelState?.oracle, state?.context?.selectedIds?.technical]);
  
  useEffect(() => {
    if (dashboardState && state.matches('home')) {
      const uniqueProjectIds = extractProjectIds(dashboardState);
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          type: "technical",
          projectId:uniqueProjectIds[0]
        }
      });
    }
  }, [dashboardState, state.value]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectedIds = (projectId) => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        type: "technical",
        projectId,
      }
    });
    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Technicals</div>
          <Tooltip id="technicals" page="home-page" />
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} onClick={toggleDropdown}>
            <span className={styles.selectedOption}>{state?.context?.selectedIds?.technical ?? initialSelectedProjectId}</span>
            <span className={styles.arrow}>
              {isOpen ? (
                <img
                  className={styles.invertedArrow}
                  src="/images/arrowDown.svg"
                  alt="arrow down"
                />
              ) : (
                <img src="/images/arrowDown.svg" alt="arrow down" />
              )}
            </span>
          </div>
          {isOpen && (
            <div className={styles.dropdownMenu}>
              {state.context?.projectIds.map((projectId) => (
                <div
                  key={projectId}
                  className={styles.dropdownItem}
                  onClick={() => selectedIds(projectId)}
                >
                  {projectId}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.gaugeContainer}>
        <img
          className={styles.mask}
          src="/images/technicalsMask.svg"
          alt="mask"
        />
        <CustomGauge residual={residuals} />
      </div>
    </div>
  );
}

export default Technicals;