import React, { useState, useEffect, useContext } from "react";
import styles from "./ModelOverview.module.css";
import Tooltip from "../Tooltip/Tooltip";
import {
  BASE_URL,
  MODEL_OVERVIEW,
  MARKET_OVERVIEW_INIT,
} from "../../constants/constants";
import axiosInstance from "../../utils/axiosInstance";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { fixToTwoDecimals } from "../../utils/helpers";

const ModelOverview = ({ dashboardState, initialModel }) => {
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const [collectionNames, setCollectionNames] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "residual",
    direction: "desc",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [tableClass, setTableClass] = useState("");
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (dashboardState) {
      const collections = Object.keys(dashboardState);
      setCollectionNames(collections);

      // Set initial collection as the initialModel if available, otherwise the first entry
      if (initialModel) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "model",
            projectId: initialModel,
          }
        });
      } else if (collections.length > 0) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "model",
            projectId: state.context?.selectedIds?.model
              ? state.context?.selectedIds?.model
              : collections[0],
          }
        });
      }
    } else {
      setCollectionNames([]);
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          type: "model",
          projectId: null,
        }
      });
      setData(MARKET_OVERVIEW_INIT);
    }
  }, [dashboardState, initialModel]);

  useEffect(() => {
    if (dashboardState) {
      const collection =
        initialModel ?? state.context?.selectedIds?.model
          ? state.context?.selectedIds?.model
          : dashboardState && Object.keys(dashboardState)[0];
      if (
        state.context?.modelState &&
        Object.keys(state.context?.modelState).length > 0 &&
        collection
      ) {
        const result = state.context.modelState;
        updateData(result, collection);
      }
    }
  }, [state.context, initialModel, dashboardState]);

  useEffect(() => {
    if (
      state.context?.selectedIds?.model &&
      state.context?.modelState &&
      Object.keys(state.context?.modelState).length > 0
    ) {
      fetchDataForCollection(state.context?.selectedIds?.model);
    }
  }, [state.context?.selectedIds?.model]);

  const fetchDataForCollection = async (collection) => {
    // setIsLoading(true);
    // try {
    // const response = await axiosInstance.get('/model_state');
    // send({type:"UPDATE_MODEL_STATE"})
    const result = state.context.modelState;
    updateData(result, collection);

    // } catch (error) {
    //   if (error.response && error.response.status === 401) {
    //     send({ type: "SIGN_OUT" });
    //     localStorage.removeItem("authState");
    //     disconnect();
    //   }
    //   console.error('Error fetching data for collection:', error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const updateData = (result, collection) => {
    const formattedData = Object.entries(result[collection] || {}).map(
      ([symbol, data]) => ({
        symbol,
        residual: data.residual,
        r2_train: data.r2_train,
        mse_train: data.mse_train,
      })
    );

    sortData(sortConfig.key, sortConfig.direction, formattedData);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectCollection = (collection) => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        type: "model",
        projectId: collection,
      }
    });
    setIsOpen(false);
  };

  const sortData = (key, direction = "desc", dataToSort = data) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);

    setSortConfig({ key, direction });

    if (sortedData.length > 5) {
      setTableClass(styles.largeTable);
    } else {
      setTableClass("");
    }
  };

  const toggleSortDirection = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    sortData(key, direction);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort ascending"
          className={styles.sortIcon}
        />
      ) : (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort descending"
          className={`${styles.sortIcon} ${styles.inverted}`}
        />
      );
    }
    return (
      <img
        src="/images/tableSortArrow.svg"
        alt="Sort"
        className={`${styles.sortIcon} ${styles.inactive}`}
      />
    );
  };

  const columns = MODEL_OVERVIEW;

  const formatValue = (key, value) => {
    if (value === "--" || value === null || value === undefined) return "--";

    if (key === "symbol") {
      return value.toUpperCase();
    }

    let numValue = typeof value === "string" ? parseFloat(value) : value;

    if (isNaN(numValue)) return "N/A";

    switch (key) {
      case "residual":
        return `${fixToTwoDecimals(numValue, 2, true)}%`;
      case "r2_train":
        return fixToTwoDecimals(numValue, 4);
      case "mse_train":
        return fixToTwoDecimals(numValue, 6);
      default:
        return value;
    }
  };

  const getResidualClass = (residual) => {
    if (residual === "--") return "";
    const numResidual = parseFloat(residual);
    return !isNaN(numResidual) && numResidual >= 0
      ? styles.positive
      : styles.negative;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Model Overview</div>
            <Tooltip id="model-overview" page="model-page" />
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {state.context?.selectedIds?.model}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {collectionNames.map((collection) => (
                  <div
                    key={collection}
                    className={styles.dropdownItem}
                    onClick={() => selectCollection(collection)}
                  >
                    {collection}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={
                      sortable ? () => toggleSortDirection(key) : undefined
                    }
                    aria-sort={
                      sortConfig.key === key ? sortConfig.direction : "none"
                    }
                  >
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  {columns.map(({ key }) => (
                    <td
                      key={key}
                      className={
                        key === "residual" ? getResidualClass(item[key]) : ""
                      }
                    >
                      {formatValue(key, item[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModelOverview;