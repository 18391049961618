import React, { useEffect, useState, useContext } from "react";
import withLayout from "../components/withLayout/withLayout";
import styles from "./Access.module.css";
import { toast } from "react-toastify";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthWrapper/AuthWrapper";
import ExpiryProgressBar from "../components/Progressbar/Progressbar";
import { calculateSubscriptionDays } from "../utils/helpers";
import { SUBSCRIPTION_ADDRESS,SUBSCRIPTION_AMOUNT,PERMANENT_AMOUNT } from "../constants/constants";

const Api = () => {
  const [subscription, setSubscription] = useState(null);
  const { isConnected, address } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  useEffect(() => {
    if (state.context?.accessToken) {
      fetchSubscriptions();
    }
  }, [state.context?.subscription, state.value]);

  const fetchSubscriptions = async () => {
    try {
      const fetchedSubscriptions = state.context?.subscription;
      setSubscription(fetchedSubscriptions);
      console.log(fetchedSubscriptions); // For debugging
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const balance = 100; // Replace with actual balance fetching logic

  return (
    <div className={styles.apiContainer}>
      {!isConnected && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backdropFilter: "blur(1px)",
            pointerEvents: "none",
            zIndex: "10",
          }}
        />
      )}

      <div style={{ textAlign: "left" }}>
        <span className={styles.title}>Access</span>
      </div>

      <div className={styles.textDiv}>
        Welcome to your Access Dashboard! This central hub allows you to manage your platform access, 
        track your active subscriptions, and monitor your usage. Here you can view your subscription status, 
        remaining time, and access credentials. Soon, you'll also be able to manage your API keys and track 
        your platform usage statistics all in one place.
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.cardContainer}>
          <div
            style={{ padding: 20, boxSizing: "border-box" }}
            className={styles.card}
          >
            {subscription?.amount_sent > 0 ? (
              <>
                <div className={styles.planDetails}>
                  <h2 className={styles.planTitle}>
                    Subscription Access
                  </h2>
                </div>
                <ExpiryProgressBar
                  expiryDate={
                    new Date(subscription?.expiry_date)
                      .toISOString()
                      .split("T")[0]
                  }
                  totalDays={calculateSubscriptionDays(
                    subscription?.amount_sent
                  )}
                />
                <div className={styles.subscriptionInfo}>
                  To extend subscription for 1 month or more, deposit{" "}
                  <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the following address:{" "}
                  <strong>{SUBSCRIPTION_ADDRESS}</strong>
                </div>
              </>
            ) : (
              // New default info section for users without a subscription
              <>
                <div className={styles.defaultInfo}>
                  <h2 className={styles.defaultTitle}>Permanent Access</h2>
                  <p>Wallet Address: {address ? `${address.slice(0, 4)}....${address.slice(-4)}` : "0x00....0000"}</p>
                  <p>Balance: {state.context?.subscription?.balance || 0} YAI</p>
                  <p>Status: <span style={{ color: "#00FF00" }}>Access Granted</span></p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div style={{ textDecoration: "none" }}>
              <div className={styles.buttonWallet}>
                <img
                  src="/images/ApiDocsIcon.svg"
                  style={{ width: "20px", height: "20px" }}
                />
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationGuard(Api);