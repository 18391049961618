import React from 'react';

const MinimizeIcon = ({ width = 20, height = 20, strokeColor = 'currentColor', strokeWidth = 3, className = '', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    stroke={strokeColor}
    strokeWidth={strokeWidth}
    strokeLinecap='round'
    strokeLinejoin='round'
    className={`feather feather-minimize-2 ${className}`}
    {...props}>
    <polyline points='4 14 10 14 10 20'></polyline>
    <polyline points='20 10 14 10 14 4'></polyline>
    <line x1='14' y1='10' x2='21' y2='3'></line>
    <line x1='3' y1='21' x2='10' y2='14'></line>
  </svg>
);

export default MinimizeIcon;
