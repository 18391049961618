import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const projectId = "154f1a1494701c3f343e2060f7273524";

const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",

};

const goerliTestnet = {
  chainId: 5,
  name: "Goerli Testnet",
  currency: "ETH",
  explorerUrl: "https://goerli.etherscan.io",
  rpcUrl: "https://rpc.goerli.mudit.blog/",
};
const sepoliaTestnet = {
  chainId: 11155111,
  name: "Sepolia Testnet",
  currency: "ETH",
  explorerUrl: "https://sepolia.etherscan.io",
  rpcUrl: "https://rpc.sepolia.org",
};

const metadata = {
  name: "YAI",
  description: "Y Oracle AI",
  url: "https://www.yoracle.ai",
  icons: ["https://avatars.mywebsite.com/"],
};

export const safeParse = (data) => {
  if (typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }
  if (typeof data === "object" && data !== null) {
    return data;
  }
  return null;
};

const originalJSONParse = JSON.parse;
JSON.parse = function (text, reviver) {
  try {
    if (typeof text === "object" && text !== null) {
      return text;
    }
    return originalJSONParse(text, reviver);
  } catch (error) {
    throw error;
  }
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    enableInjected: true,
    enableEIP6963: true,
  }),

  chains: [mainnet, goerliTestnet, sepoliaTestnet],

  projectId,
});
