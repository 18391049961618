import React from "react";
import { Link } from "react-router-dom";
import styles from "./Tooltip.module.css";

const Tooltip = ({ id, page }) => {
  return (
    <div className={styles.infoIconWrapper}>
      <Link to={`/docs#${page}?${id}`} className={styles.infoButton}>
        <img
          src="/images/infoIcon.svg"
          alt="info"
          className={styles.infoIcon}
        />
      </Link>
    </div>
  );
};

export default Tooltip;
