import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthWrapper/AuthWrapper.js';
import withAuthenticationGuard from '../components/guards/withAuthWrapper';
import HistoricalOverview from '../components/HistoricalOverview/HistoricalOverview';
import StrategyOverview from '../components/StrategyOverview/StrategyOverview';
import Trades from '../components/Trades/Trades';
import withLayout from '../components/withLayout/withLayout';

const Strategy = ({ fullWidth }) => {
  const [performanceData, setPerformanceData] = useState(null);

  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const handleDataFetched = useCallback((data) => {
    setPerformanceData(data);
  }, []);

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey")
      
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  const handleModelProjectChange = useCallback(
    (model, projectId, clearStrategyHistory = false) => {
      if (state.matches("strategy.idle")) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "strategy",
            model,
            projectId,
          }
        });
      }
    },
    [state.value]
  );

  const renderComponents = (data) => (
    <>
      <HistoricalOverview
        onDataFetched={data ? handleDataFetched : null}
        key={fullWidth}
        selectedModel={data ? state.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        onModelProjectChange={data ? handleModelProjectChange : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <StrategyOverview
        performanceData={data ? performanceData : null}
        selectedModel={data ? state.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <Trades
        dashboardState={data ? state.context.dashboardState : null}
        selectedModel={data ? state.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
      />
    </>
  );

  return (
    <>
      {isConnected ? (
        renderComponents(state.context.dashboardState)
      ) : (
        <>
       
          {renderComponents(null)}
        </>
      )}
    </>
  );
};

export default withAuthenticationGuard(Strategy);