import React, { createContext, useContext, useState } from 'react';

// Create a context for the model and project ID
const ModelContext = createContext();

export const useModelContext = () => useContext(ModelContext);

export const ModelProvider = ({ children }) => {
  const [model, setModel] = useState(null);
  const [projectID, setProjectID] = useState(null);

  return <ModelContext.Provider value={{ model, setModel, projectID, setProjectID }}>{children}</ModelContext.Provider>;
};
