import React, { useState, useEffect } from 'react';
import styles from './Layout.module.css';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Header from '../../components/Header/Header.js';
import MinimizeIcon from '../Icons/MinimizeIcon.js';
import MaximizeIcon from '../Icons/MaximizeIcon.js';
import ConnectModal from '../ConnectModal/ConnectModal.jsx';
import HamburgerIcon from '../Icons/HamburgerIcon.js';
import { useNavigate, Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  // Create a new functional component
  // const withLayout = (WrappedComponent) => {
  const DashboardLayout = () => {
    const [fullWidth, setFullWidth] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 1100);

    const [sidebarToggled, setSidebarToggled] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 1100 && !sidebarToggled) {
          setIsSidebarVisible(true);
        } else if (window.innerWidth <= 1100 && !sidebarToggled) {
          setIsSidebarVisible(false);
        }
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [sidebarToggled]);

    const navigate = useNavigate();

    const toggleSidebar = () => {
      setIsSidebarVisible((prev) => !prev);
      setSidebarToggled(true);
    };

    const toggleWidth = () => {
      setFullWidth((prev) => !prev);
    };

    const handleLogoClick = () => {
      navigate('/');
    };

    return (
      <>
        <div className={styles.hamburger} onClick={toggleSidebar}>
          <HamburgerIcon />
        </div>

        <div className={`${fullWidth ? styles.fullWidth : styles.mainContainer}`}>
          <div className={` ${fullWidth ? styles.fullWidth : styles.subContainer}`}>
            <div className={`${styles.leftContainer} ${isSidebarVisible ? styles.visible : ''}`}>
              {' '}
              <Sidebar onClick={handleLogoClick} />
            </div>
            <div className={`${fullWidth ? '' : styles.rightContainer}`}>
              <div className={styles.logo} onClick={handleLogoClick}>
                <img src='images/yaiLogo.svg' alt='Logo' />
              </div>
              <div className={`${fullWidth ? '' : styles.border}`}>
                <div className={styles.rightTopContainer}>
                  <Header />
                </div>
                <div className={`${fullWidth ? '' : styles.gradient}`}>
                  {/* <ConnectModal/> */}
                  <div className={`${fullWidth ? styles.fullWidthRightBottomContainer : styles.rightBottomContainer}`}>
                    <Outlet fullWidth={fullWidth} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={toggleWidth}>{fullWidth ? <MinimizeIcon /> : <MaximizeIcon />}</button>
          </div>
        </div>
      </>
    );
  };

  // // Set the display name for the HOC
  // WithLayout.displayName = `WithLayout(${getDisplayName(Outlet)})`;
  // console.log(WithLayout.displayName);
  return DashboardLayout;
};

// Helper function to get the display name of a component
// function getDisplayName(WrappedComponent) {
//   return WrappedComponent.displayName || WrappedComponent.name || 'Component';
// }

export default DashboardLayout;
