import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthWrapper from './AuthWrapper/AuthWrapper';
import { ModelProvider } from './ContextProvider';
import { WalletProvider } from './context/WalletContext';
import Docs from './pages/Docs';
import Home from './pages/Home';
import Model from './pages/Model';
import Strategy from './pages/Strategy';
import Access from './pages/Access';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyles.css';
import { ErrorProvider } from './context/ErrorContext';
import DashboardLayout from './components/withLayout/withLayout';

function AppRoutes() {
  const Layout = DashboardLayout();

  return (
    <ErrorProvider>
      <WalletProvider>
        <ModelProvider>
          <Router>
            <AuthWrapper>
              <Routes>
                <Route element={<Layout />}>
                  {/* <Route path='/' element={<DashboardLayout />}> */}
                  <Route path='/' element={<Home />} />
                  <Route path='/model' element={<Model />} />
                  <Route path='/access' element={<Access />} />
                  <Route path='/docs' element={<Docs />} />
                  <Route path='/strategy' element={<Strategy />} />
                  {/* Catch-all route */}
                  <Route path='*' element={<Navigate to='/' replace />} />
                </Route>
              </Routes>

              <ToastContainer className='custom-toast' progressClassName='custom-progress-bar' />
            </AuthWrapper>
          </Router>
        </ModelProvider>
      </WalletProvider>
    </ErrorProvider>
  );
}

export default AppRoutes;
