import React from 'react';

const MaximizeIcon = ({ width = 20, height = 20, strokeColor = 'currentColor', strokeWidth = 3, className = '', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    stroke={strokeColor}
    strokeWidth={strokeWidth}
    strokeLinecap='round'
    strokeLinejoin='round'
    className={`feather feather-maximize-2 ${className}`}
    {...props}>
    <polyline points='15 3 21 3 21 9'></polyline>
    <polyline points='9 21 3 21 3 15'></polyline>
    <line x1='21' y1='3' x2='14' y2='10'></line>
    <line x1='3' y1='21' x2='10' y2='14'></line>
  </svg>
);

export default MaximizeIcon;
