import './App.css';
import AppRoutes from './AppRoutes';
import './config/WalletConfig'

function App() {
  return (
    <div className='App'>
      <div className='mainContainer'>
        <AppRoutes />
      </div>
    </div>
  );
}

export default App;
