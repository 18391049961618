import {
  useDisconnect,
  useWeb3Modal,
} from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { useWalletContext } from "../../context/WalletContext.js";
import styles from "./WalletButtons.module.css";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";


const WalletButtons = () => {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [balance, setBalance] = useState(null);
  const { handleWalletBalance } = useWalletContext();
  const { state, send } = useContext(AuthContext);
  const { isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    handleWalletBalance(balance);
  }, [balance]);

  return (
    <>
      <div className={styles.buttonWallet} onClick={() => open()}>
        <img src="/images/walletIcon.svg" alt="Wallet" />
        Wallet
      </div>
      {(isConnected && !state.matches("idle")) && (
          <div
            className={styles.buttonLogOut}
            onClick={() => {
              send({ type: "SIGN_OUT" });
              // localStorage.removeItem("authState");
              // disconnect();
            }}
          >
            <img src="/images/logoutIcon.svg" alt="Log Out" />
            Log Out
          </div>
        )}
    </>
  );
};

export default WalletButtons;
