import React, { useEffect, useState, useContext } from "react";
import Plot from "react-plotly.js";
import {
  CUSTOM_HEATMAP_COLORS,
  HEATMAP_INITIAL
} from "../../constants/constants";
import ToolTip from "../Tooltip/Tooltip";
import styles from "./StateHeatmap.module.css";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { fixToTwoDecimals } from "../../utils/helpers";

function StateHeatmap({ dashboardState }) {
  //console.log(dashboardState);
  const [data, setData] = useState(HEATMAP_INITIAL);
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [project_ids, setProjectIds] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);


  useEffect(() => {
    if (dashboardState) {
      const allModels = Object.keys(dashboardState || {});
      const allProjectIds =  [
        ...new Set(allModels.flatMap((model) => dashboardState[model])),
      ];

      // Sort models by name length (longest to shortest)
      const sortedModels = allModels.sort((a, b) => b.length - a.length);

      setModels(sortedModels);
      setProjectIds(allProjectIds);
    }
  }, [dashboardState]);

  useEffect(() => {
      const fetchData = async () => {
        // try {
          // const collectionsParam = ""; // Empty string to return data for all available collections
          // const projectIdsParam = "";
          // const queryParams = `${collectionsParam}&${projectIdsParam}`;
          // const response = await axiosInstance.get(
          //   `/model_state?${queryParams}`
          // );
          const result = state.context.modelState
          setData(result);
          // setModels(Object.keys(result)); // Set models based on the API response
          processData(result);
        // } catch (error) {
        //     if (error.response && error.response.status === 401) {
        //     send({ type: "SIGN_OUT" });
        //     localStorage.removeItem("authState");
        //     disconnect();
        //   } else {
        //     console.error("Error fetching data:", error);
        //   }
        // } finally {
        //   setLoading(false);
        // }
      };
      if (dashboardState  && state.context?.modelState && Object.keys(state.context?.modelState).length > 0) {

      fetchData();
    }
  }, [dashboardState,state.context]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      processData(data);
    }
  }, [data, models]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const processData = (data) => {
    const heatmapValues = models.map((model) =>
      project_ids.map((project) => data[model]?.[project]?.z_score ?? 0)
    );
    const residualValues = models.map((model) =>
      project_ids.map((project) => data[model]?.[project]?.residual ?? 0)
    );
    // const residualValues = models.map((model) => project_ids.map((project) => data[model]?.[project]?.residual ?? 0));
    // console.log('heatmapValues:', heatmapValues);

    setHeatmapData({
      z: heatmapValues,
      x: project_ids,
      y: models,
      type: "heatmap",
      colorscale: CUSTOM_HEATMAP_COLORS,
      showscale: true,
      colorbar: { title: "Z-Score" },
      text: residualValues.map((row) =>
        row.map((value) => `${fixToTwoDecimals(value ?? 0, 2, true)}%`)
      ),
      texttemplate: "%{text}",
      hoverinfo: "text",
      zmin: -5,
      zmax: 5,
    });
  };

  const getPlotDimensions = () => {
    if (dimensions.width < 1415) {
      return { width: "100%", height: "100%" };
    } else if (dimensions.width < 1540) {
      return { width: 500, height: 450 };
    } else if (dimensions.width < 1650) {
      return { width: 540, height: 450 };
    } else {
      return { width: 620, height: 450 };
    }
  };

  const getFontSize = () => {
    if (dimensions.width < 600) {
      return 16;
    } else if (dimensions.width < 1440) {
      return 22;
    } else if (dimensions.width < 1600) {
      return 24;
    } else {
      return 28;
    }
  };
  const fontSize = getFontSize();

  const plotDimensions = getPlotDimensions();

  return (
    // <div>
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>Heatmap</div>
        <ToolTip id="heatmap" page="home-page" />
      </div>{" "}
      <div className={styles.plotContainer}>
        <Plot
          data={[
            {
              ...heatmapData,
              showscale: dimensions.width > 500, // Show color bar only when width is greater than 1100px
              colorbar:
                dimensions.width > 500 ? { title: "Z-Score" } : false, // Only add color bar title if the width is greater than 1100px
            },
          ]}
          layout={{
            // width: 600,
            // height: 450,
            width: dimensions.width < 900 ? "" : plotDimensions.width,
            height: dimensions.width < 900 ? "" : plotDimensions.height,
            autosize: true,
            xaxis: {
              title: {
                text: "Asset",
                // standoff: 50, // Add padding below the x-axis title
              },
              color: "#fff",
              showgrid: false, // Remove x-axis grid lines
              zeroline: false, // Remove x-axis zero line
            },
            yaxis: {
              title: {
                text: "Model",
                // standoff: 70, // Add padding to the right of the y-axis title
              },
              color: "#fff",

              showgrid: false, // Remove y-axis grid lines
              zeroline: false, // Remove y-axis zero line
            },
            paper_bgcolor: "rgba(44, 44, 44, 0.0)",
            plot_bgcolor: "rgba(44, 44, 44, 0)",
            font: { color: "#fff" },

            margin: { t: 80, pad: 6 },
          }}
          config={{
            displayModeBar: false,
            staticPlot: true,
            useResizeHandler: true,
            responsive: true,
          }}
          style={{
            width: dimensions.width < 900 ? "100%" : "auto",
            display: "flex",
            justifyContent: dimensions.width > 900 ? "center" : "unset",
            height: dimensions.width > 700 ? "auto" : "100%",
          }}
        />
      </div>
    </div>
    // </div>
  );
}

export default StateHeatmap;
