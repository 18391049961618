import React, { useContext } from 'react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import styles from './SubscriptionModal.module.css';
import Loader from '../Loader/Loader';
import { SUBSCRIPTION_ADDRESS, SUBSCRIPTION_AMOUNT } from '../../constants/constants';
import { EXTERNAL_LINKS as SOCIAL_MEDIA_LINKS } from '../../constants/constants';

const SubscriptionModal = () => {
  const { state, send } = useContext(AuthContext);
  return (
    <div className={styles.modalOverlay}>
      {state.matches('verifyingTransaction') && (
        <div className={styles.modalContent}>
          <div className={styles.title}>Scanning for Subscription TX</div>
          <div className={styles.subtitle}>
            To subscribe for 1 month or more, deposit <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the following address:{' '}
            <strong>{SUBSCRIPTION_ADDRESS}</strong>
          </div>

          <div className={styles.loaderContainer}>
            <Loader />
          </div>
          <div className={styles.socialMedia}>
            <a href={SOCIAL_MEDIA_LINKS.X} target='_blank' rel='noopener noreferrer'>
              <img
                style={{ height: '30px', width: '30px', background: '#151814', padding: '10px', borderRadius: '50%' }}
                src='/images/twitter.svg'
                alt='X'
              />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.Telegram} target='_blank' rel='noopener noreferrer'>
              <img
                style={{ height: '30px', width: '30px', background: '#151814', padding: '10px', borderRadius: '50%' }}
                src='/images/telegram.svg'
                alt='Telegram'
              />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.Docs} target='_blank' rel='noopener noreferrer'>
              <img style={{ height: '50px', width: '50px' }} src='/images/docsIcon.svg' alt='Docs' />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionModal;
